import boss from "./boss.png";
import "./App.css";
import { useEffect, useState } from "react";

function App() {
  const [configuracion, setConfiguracion] = useState({});
  const [configuracionLoading, setConfiguracionLoading] = useState(true);

  const [votante, setVotante] = useState({});
  const [votanteLoading, setVotanteLoading] = useState(false);

  const [cedula, setCedula] = useState("");

  const [informacion, setInformacion] = useState(true);

  const getConfiguracion = async () => {
    setConfiguracionLoading(true);
    let resp = await fetch("/configuracion.json");
    let data = await resp.json();

    setConfiguracion(data);
    // await new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve();
    //   }, 1000);
    // });
    setConfiguracionLoading(false);
  };

  useEffect(() => {
    getConfiguracion();
  }, []);

  const getVotante = async () => {
    try {
      setVotanteLoading(true);
      setVotante({});
      let resp = await fetch(
        `https://hosting.com.py/padron.php?cedula=${cedula}`
      );
      let data = await resp.json();

      setVotante(data);
      setVotanteLoading(false);
    } catch (error) {
      console.log({ error });
    }
  };

  if (configuracionLoading) {
    return (
      <div className="flex items-center justify-center h-full ">
        <div className="h-20 w-20 animate-bounce">
          <span className="flex h-20 w-20 relative">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-20 w-20 bg-red-500"></span>
          </span>
        </div>
      </div>
    );
  }

  const renderInfo = () => {
    return (
      <div className="h-3/5 md:flex-grow md:h-auto md:w-1/2 bg-slate-50 flex items-center justify-center">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <div className="uppercase text-6xl font-bold text-red-600">
                {configuracion.nombre}
              </div>
              <div className="uppercase text-6xl font-bold text-red-600">
                {configuracion.cargo}
              </div>
            </div>
            <div className="capitalize text-6xl font-bold ">
              {configuracion.lista}
            </div>
          </div>
          <button
            className="border-4 text-xl p-1 rounded-lg border-slate-700"
            onClick={() => setInformacion(false)}
          >
            Consultar padron
          </button>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="h-3/5 md:flex-grow md:h-auto md:w-1/2 bg-slate-50 flex items-center justify-center relative">
        <div className="absolute left-0 right-0 top-0 bottom-0 overflow-auto  p-10 overflow-x-hidden">
          <div className="flex flex-col justify-start  gap-4">
            <div className="flex flex-col justify-start  gap-2">
              <div className="text-xl">Buscar por numero de cedula</div>
              <div className="flex flex-row gap-2">
                <input
                  type="number"
                  value={cedula}
                  className={"flex-grow text-xl p-2"}
                  onChange={({ target }) => setCedula(target.value)}
                />
                <button
                  onClick={() => getVotante()}
                  className="capitalice border-2 border-slate-700 text-xl p-2 rounded-md"
                >
                  buscar
                </button>
              </div>
            </div>
            {!!votanteLoading && <div>cargando...</div>}
            {!votanteLoading && (
              <>
                {!!votante?.votante_cedula
                  ? renderVotante()
                  : "No se encuentra el votante"}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderVotante = () => {
    let partes = ["votante", "seccional", "local", "vota"];
    return (
      <div className="flex flex-col justify-start  gap-4">
        <div className="text-xl">Informacion del votante</div>
        {partes.map((parte) => (
          <div
            key={parte}
            className="bg-white rounded-lg shadow flex flex-col gap-2 p-2"
          >
            <div className="text-xl uppercase">{parte}</div>
            {Object.keys(votante)
              .filter((key) => key.includes(parte + "_"))
              .map((key) => {
                let value = votante[key]
                  .replace(" 00:00:00", "")
                  .replace(/\//g, " ");
                let title = key.replace(parte + "_", "").replace(/_/g, " ");
                return (
                  <div className="flex flex-col justify-start  gap-1">
                    <div className="text-lg capitalize underline">{title}</div>
                    <div className="text-xl">{value}</div>
                  </div>
                );
              })}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full text-slate-800">
      <div className="">
        <div className="h-10 bg-red-600"></div>
      </div>
      <div className="flex flex-col md:flex-row-reverse flex-grow">
        <div className="h-2/5 w-full md:h-auto md:w-1/2 flex items-end justify-center ">
          <div
            className="bg-bottom h-5/6 w-full bg-contain bg-no-repeat"
            style={{ backgroundImage: `url(${boss})` }}
          ></div>
        </div>
        {informacion ? renderInfo() : renderForm()}
      </div>
    </div>
  );
}

export default App;
